<template>
  <vx-card title="Visit Plan Generate">
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLabelTerritory"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Route Assignment</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="true"
          :options="optionRouteAssignment"
          v-model="selectedRouteAssignment"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Salesman</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="false"
          :options="optionPersonals"
          v-model="selectedPersonals"
          placeholder="Default all"
          @search="
            (search, loading) => {
              onSearchPersonal(search, loading);
            }
          "
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Date From</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="date"
          :inline="false"
          v-model="dateFrom"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('dateFrom')">{{
          errors.first("dateFrom")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Date To</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="date"
          :inline="false"
          v-model="dateTo"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('dateTo')">{{
          errors.first("dateTo")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleGenerate()"
          >Generate</vs-button
        >
      </div>
    </div>

    <br />
    <div clss="vx-row">
      <data-table-generate
        :baseUrl="this.baseUrl"
        :draw="this.drawTableGenerate"
      ></data-table-generate>
    </div>
    <div clss="vx-row">
      <vs-tabs v-model="activeTab">
        <vs-tab label="Draft">
          <div class="tab-text">
            <vs-tabs v-model="activeTabDraft">
              <vs-tab label="Generated">
                <div class="tab-text">
                  <data-table
                    :baseUrl="this.baseUrl"
                    :draw="this.draw"
                    status="Draft"
                    @viewDaily="handleViewDaily"
                  ></data-table>
                </div>
              </vs-tab>
              <vs-tab label="Daily">
                <tab-daily
                  :baseUrl="this.baseUrl"
                  status="Draft"
                  :customerVisitPlanID="customerVisitPlanID"
                ></tab-daily>
              </vs-tab>
              <vs-tab label="Customer">
                <tab-customer
                  :baseUrl="this.baseUrl"
                  status="Draft"
                ></tab-customer>
              </vs-tab>
              <vs-tab label="Log">
                <tab-log :baseUrl="this.baseUrl" status="Draft"></tab-log>
              </vs-tab>
            </vs-tabs>
          </div>
        </vs-tab>
        <vs-tab label="Released">
          <div class="tab-text">
            <vs-tabs v-model="activeTabReleased">
              <vs-tab label="Generated">
                <div class="tab-text">
                  <data-table
                    :baseUrl="this.baseUrl"
                    status="Released"
                    @viewDaily="handleViewDaily"
                  ></data-table>
                </div>
              </vs-tab>
              <vs-tab label="Daily">
                <tab-daily
                  :baseUrl="this.baseUrl"
                  status="Released"
                  :customerVisitPlanID="customerVisitPlanID"
                ></tab-daily>
              </vs-tab>
              <vs-tab label="Customer">
                <tab-customer
                  :baseUrl="this.baseUrl"
                  status="Released"
                ></tab-customer>
              </vs-tab>
              <vs-tab label="Log">
                <tab-log :baseUrl="this.baseUrl"></tab-log>
              </vs-tab>
            </vs-tabs>
          </div>
        </vs-tab>
        <vs-tab label="Inactive">
          <div class="tab-text">
            <vs-tabs v-model="activeTabInactive">
              <vs-tab label="Generated">
                <div class="tab-text">
                  <data-table
                    :baseUrl="this.baseUrl"
                    status="Inactive"
                    @viewDaily="handleViewDaily"
                  ></data-table>
                </div>
              </vs-tab>
              <vs-tab label="Daily">
                <tab-daily
                  :baseUrl="this.baseUrl"
                  status="Inactive"
                  :customerVisitPlanID="customerVisitPlanID"
                ></tab-daily>
              </vs-tab>
              <vs-tab label="Customer">
                <tab-customer
                  :baseUrl="this.baseUrl"
                  status="Inactive"
                ></tab-customer>
              </vs-tab>
              <vs-tab label="Log">
                <tab-log :baseUrl="this.baseUrl"></tab-log>
              </vs-tab>
            </vs-tabs>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>

    <br />
    <!-- <div clss="vx-row">
      <vs-tabs v-model="activeTab">
        <vs-tab label="Draft">
          <div class="tab-text">
            <data-table
              :baseUrl="this.baseUrl"
              :draw="draw"
              status="Draft"
              @viewDaily="handleViewDaily"
            ></data-table>
          </div>
        </vs-tab>
        <vs-tab label="Released">
          <div class="tab-text">
            <data-table
              :baseUrl="this.baseUrl"
              status="Released"
              @viewDaily="handleViewDaily"
            ></data-table>
          </div>
        </vs-tab>
        <vs-tab label="Daily">
          <tab-daily
            :baseUrl="this.baseUrl"
            :customerVisitPlanID="customerVisitPlanID"
          ></tab-daily>
        </vs-tab>
        <vs-tab label="Customer">
          <tab-customer :baseUrl="this.baseUrl"></tab-customer>
        </vs-tab>
        <vs-tab label="Log">
          <tab-log :baseUrl="this.baseUrl"></tab-log>
        </vs-tab>
      </vs-tabs>
    </div> -->
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import DataTableGenerate from "./DataTableGenerate.vue";
import TabDaily from "./TabDaily.vue";
import TabCustomer from "./TabCustomer.vue";
import TabLog from "./TabLog.vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  components: {
    Datepicker,
    DataTable,
    DataTableGenerate,
    TabDaily,
    TabCustomer,
    TabLog,
    vSelect,
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/sfa/v1/visit-plan-generate",
      optionTerritory: [],
      selectedTerritory: null,
      optionRouteAssignment: [],
      selectedRouteAssignment: [],
      optionPersonals: [],
      selectedPersonals: [],
      draw: 0,
      drawTableGenerate: 0,
      dateFrom: null,
      dateTo: null,
      dateNow: new Date(),

      customerVisitPlanID: null,
      test: 0,
    };
  },
  methods: {
    customLabelTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLabelRouteAssignment({ code, valid_from, valid_to }) {
      return `${code} (${valid_from} - ${valid_to}`;
    },
    handleGenerate() {
      if (this.selectedRouteAssignment.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Route Assignment is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return false;
      }

      if (!this.dateFrom || !this.dateTo) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Date from - to is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return false;
      }

      var dateFrom = new Date(moment(this.dateFrom).format("DD MMM YYYY"));
      var dateTo = new Date(moment(this.dateTo).format("DD MMM YYYY"));

      var routeAssignmentIDs = [];
      this.selectedRouteAssignment.forEach((el) => {
        var routeAssignmentValidFrom = new Date(el.valid_from);
        var routeAssignmentValidTo = new Date(el.valid_to);
        if (
          dateFrom >= routeAssignmentValidFrom &&
          dateFrom <= routeAssignmentValidTo &&
          dateTo >= routeAssignmentValidFrom &&
          dateTo <= routeAssignmentValidTo
        ) {
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text:
              "Date from - to is required in route assignment " +
              el.code +
              " validity range",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return false;
        }

        routeAssignmentIDs.push(el.id);
      });

      if (routeAssignmentIDs.length == 0) {
        return false;
      }

      var personalIDs = [];
      this.selectedPersonals.forEach((el) => {
        personalIDs.push(el.personal_id);
      });

      this.$vs.loading();
      let body = {
        territory_id: this.selectedTerritory.id,
        route_assignment_ids: routeAssignmentIDs,
        date_from: this.dateFrom
          ? moment(this.dateFrom).format("YYYY-MM-DD")
          : null,
        date_to: this.dateTo ? moment(this.dateTo).format("YYYY-MM-DD") : null,
        date: this.dateTo ? moment(this.dateNow).format("YYYY-MM-DD") : null,
        personal_ids: personalIDs,
      };
      this.$http
        .post(this.baseUrl + "/create", body)
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.selectedTerritory = null;
            this.selectedRouteAssignment = [];
            this.dateFrom = null;
            this.dateTo = null;

            this.activeTab = 0;
            this.activeTabDraft = 0;

            this.drawTableGenerate++;
            this.draw++;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },

    handleViewDaily({ id, status }) {
      if (status == "Draft") {
        this.activeTabDraft = 1;
      } else if (status == "Released") {
        this.activeTabReleased = 1;
      } else {
        this.activeTabInactive = 1;
      }
      this.customerVisitPlanID = id;
    },

    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = null;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getRouteAssignment() {
      if (this.selectedTerritory) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/route-assignment", {
            params: {
              length: 0,
              // order: "code",
              // sort: "asc",
              // fix 22 mei 2023, dropdown / field RA diurutkan berdasarkan updated_at
              order: "updated_at",
              sort: "desc",
              territory_id: this.selectedTerritory.id,
              status: "Assigned",
              date: moment(this.dateNow).format("YYYY-MM-DD"),
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (resp.data.records) {
                resp.data.records.map(function (x) {
                  return (x.label =
                    x.code + " (" + x.valid_from + " - " + x.valid_to + ")");
                });
                this.optionRouteAssignment = resp.data.records;
              } else {
                this.optionRouteAssignment = [];
                this.selectedRouteAssignment = null;
              }
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    getPersonal() {
      if (this.selectedRouteAssignment.length > 0) {
        var routeAssignmentIDs = [];
        this.selectedRouteAssignment.forEach((el) => {
          routeAssignmentIDs.push(el.id);
        });

        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/sales-route-assignment", {
            params: {
              length: 0,
              route_assignment_ids: routeAssignmentIDs,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (resp.data.records) {
                resp.data.records.map(function (x) {
                  return (x.label =
                    x.work_id_number +
                    " (" +
                    x.code_external +
                    ") " +
                    x.name +
                    " (" +
                    x.route_assignment_code +
                    ")");
                });
                this.optionPersonals = resp.data.records;
              } else {
                this.optionPersonals = [];
              }
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    onSearchPersonal(search, loading) {
      if (search.length && this.selectedRouteAssignment) {
        loading(true);
        this.searcPersonal(loading, search, this);
      }
    },
    searcPersonal: _.debounce((loading, search, t) => {
      t.$http
        .get(t.baseUrl + "/sales", {
          params: {
            length: 10,
            search: search.trim(),
            route_assignment_id: t.selectedRouteAssignment.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              resp.data.records.map(function (x) {
                return (x.label =
                  x.work_id_number + " (" + x.code_external + ") " + x.name);
              });
              t.optionPersonals = resp.data.records;
            } else {
              t.optionPersonals = [];
            }
            loading(false);
          } else {
            t.optionPersonals = [];
            loading(false);
          }
        });
    }, 350),
  },
  mounted() {
    this.getTerritory();
  },
  computed: {
    activeTab: {
      get() {
        return this.$store.state.master.visitPlanGenerate.activeTab;
      },
      set(val) {
        this.$store.commit("master/visitPlanGenerate/setActiveTab", val);
      },
    },
    activeTabDraft: {
      get() {
        return this.$store.state.master.visitPlanGenerate.activeTabDraft;
      },
      set(val) {
        this.$store.commit("master/visitPlanGenerate/setActiveTabDraft", val);
      },
    },
    activeTabReleased: {
      get() {
        return this.$store.state.master.visitPlanGenerate.activeTabReleased;
      },
      set(val) {
        this.$store.commit(
          "master/visitPlanGenerate/setActiveTabReleased",
          val
        );
      },
    },
    activeTabInactive: {
      get() {
        return this.$store.state.master.visitPlanGenerate.activeTabInactive;
      },
      set(val) {
        this.$store.commit(
          "master/visitPlanGenerate/setActiveTabInactive",
          val
        );
      },
    },
  },
  watch: {
    selectedTerritory() {
      this.getRouteAssignment();
    },
    selectedRouteAssignment(val) {
      var indexSelectedPersonalToRemove = [];

      if (val.length > 0) {
        val.forEach((element) => {
          this.selectedPersonals.forEach((val, indexPersonal) => {
            if (val.route_assignment_id != element.id) {
              if (!indexSelectedPersonalToRemove.includes(indexPersonal)) {
                indexSelectedPersonalToRemove.push(indexPersonal);
              }
            }
          });
        });

        indexSelectedPersonalToRemove.forEach((val) => {
          this.selectedPersonals.splice(val, 1);
        });
      } else {
        this.selectedPersonals = [];
      }

      this.getPersonal();
    },
    customerVisitPlanID(val) {
      console.log(val);
    },
    "$store.state.master.visitPlanGenerate.activeTab": (val) => {
      console.log(val);
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>

<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th></vs-th>
        <vs-th sort-key="cvpl.date">Date</vs-th>
        <vs-th sort-key="cvpl.code">Daily Code</vs-th>
        <vs-th sort-key="t.name">Territory</vs-th>
        <vs-th sort-key="p.name">Sales</vs-th>
        <vs-th sort-key="">Route Plan</vs-th>
        <vs-th sort-key="rp.name">Route Assignment</vs-th>
        <vs-th sort-key="">Customer Total</vs-th>
        <vs-th sort-key="cvpl.is_pulled" v-if="status == 'Released'"
          >Pull Status</vs-th
        >
        <vs-th sort-key="cvp.status">Generate Status</vs-th>
        <vs-th sort-key="cvpl.status">Daily Visit Status</vs-th>
        <vs-th sort-key="" v-if="status == 'Released'">Collection</vs-th>
        <vs-th sort-key="cvpl.in_time">In Time</vs-th>
        <vs-th sort-key="cvpl.out_time">Out Time</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button
                @click.stop="handleView(data[indextr].id)"
                size="small"
                color="rgb(41, 147, 138)"
                icon-pack="feather"
                icon="icon-eye"
                title="View Customer"
                style="margin-right: 5px"
              />
            </div>
          </vs-td>
          <vs-td :data="data[indextr].date">
            {{ data[indextr].date }}
          </vs-td>
          <vs-td :data="data[indextr].code">
            {{ data[indextr].code }}
          </vs-td>
          <vs-td :data="data[indextr].territory_code">
            {{
              data[indextr].territory_code + " " + data[indextr].territory_name
            }}
          </vs-td>
          <vs-td :data="data[indextr].work_id_number">
            {{
              data[indextr].work_id_number +
              " (" +
              data[indextr].code_external +
              ") " +
              data[indextr].personal_name
            }}
          </vs-td>
          <vs-td :data="data[indextr].route_plans">
            <div v-html="data[indextr].route_plans"></div>
          </vs-td>
          <!-- <vs-td :data="data[indextr].route_plan_code">
            {{
              data[indextr].route_plan_code + " " + data[indextr].schedule_type
            }}
          </vs-td> -->
          <vs-td :data="data[indextr].route_assignment_code">
            {{ data[indextr].route_assignment_code }}
          </vs-td>
          <vs-td :data="data[indextr].customer_total">
            {{ data[indextr].customer_total }}
          </vs-td>
          <vs-td :data="data[indextr].is_pulled" v-if="status == 'Released'">
            {{ data[indextr].is_pulled }}
          </vs-td>
          <vs-td :data="data[indextr].customer_visit_plan_status">
            {{ data[indextr].customer_visit_plan_status }}
          </vs-td>
          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>
          <vs-td
            :data="data[indextr].total_collection"
            v-if="status == 'Released'"
          >
            {{ data[indextr].total_collection }}
          </vs-td>
          <vs-td :data="data[indextr].in_time">
            {{ utcToLocal(data[indextr].in_time) }}
          </vs-td>
          <vs-td :data="data[indextr].out_time">
            {{ utcToLocal(data[indextr].out_time) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    detail: {
      type: Boolean,
    },
    customerVisitPlanID: {
      type: Number,
    },
    territoryID: {
      type: Number,
    },
    salesPersonalID: {
      type: Number,
    },
    routePlanID: {
      type: Number,
    },
    routeAssignmentID: {
      type: Number,
    },
    dateFrom: {
      type: Date,
    },
    dateTo: {
      type: Date,
    },
    status: {
      type: String,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        // order: "id",
        order: "cvpl.updated_at",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleView(id) {
      this.$emit("viewCustomer", {
        id: id,
        status: this.status,
      });
    },
    getData() {
      if (this.customerVisitPlanID) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/daily/table", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              customer_visit_plan_id: this.customerVisitPlanID,
              territory_id: this.territoryID,
              personal_id: this.salesPersonalID,
              route_plan_id: this.routePlanID,
              route_assignment_id: this.routeAssignmentID,
              date_from: this.dateFrom,
              date_to: this.dateTo,
              status: this.status,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/" + this.deleteId + "/delete")
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id) {
      this.$router.push({
        name: "route-assignment-edit",
        params: { id: id },
      });
    },
    handleRelease(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to release this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .put(this.baseUrl + "/" + id + "/status", {
              status: "Released",
            })
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully released",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.$vs.loading.close();
              this.getData();
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },
    handleCancel(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to cancel this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .put(this.baseUrl + "/" + id + "/status", {
              status: "Canceled",
            })
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully canceled",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.$vs.loading.close();
              this.getData();
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },
    utcToLocal(val) {
      if (val && val != "0001-01-01T00:00:00Z") {
        return moment(val)
          .add(-7, "h")
          .utc()
          .local()
          .format("DD MMM YYYY HH:mm:ss");
      }
    },
  },
  mounted() {},
  watch: {
    draw() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

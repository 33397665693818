<template>
  <div class="tab-text">
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLabelTerritory"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Sales</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedSales"
          :options="optionSales"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLabelTerritory"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Route Plan</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedRoutePlan"
          :options="optionRoutePlan"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLabelTerritory"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Route Assignment</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedRouteAssignment"
          :options="optionRouteAssignment"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLabelTerritory"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Date From</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker v-model="dateFrom" placeholder="Select Date" />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Date To</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker v-model="dateTo" placeholder="Select Date" />
      </div>
    </div> -->
    <table-daily
      :baseUrl="this.baseUrl"
      :draw="this.draw"
      :status="this.status"
      :customerVisitPlanID="this.customerVisitPlanID"
      @viewCustomer="handleViewCustomer"
    ></table-daily>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import TableDaily from "./DataTableDaily.vue";
export default {
  components: {
    Datepicker,
    TableDaily,
  },
  props: {
    baseUrl: {
      type: String,
    },
    status: {
      type: String,
    },
    customerVisitPlanID: {
      type: Number,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        optionTerritory: [],
        selectedTerritory: null,
        optionRouteAssignment: [],
        selectedRouteAssignment: null,
        optionRoutePlan: [],
        selectedRoutePlan: null,
        optionSales: [],
        selectedSales: null,
        dateFrom: null,
        dateTo: null,
        draw: 0,
      };
    },
    handleViewCustomer({ id, status }) {
      this.$store.commit(
        "master/visitPlanGenerate/setCustomerVisitPlanLineID",
        id
      );

      if (status == "Draft") {
        this.activeTabDraft = 2;
      } else if (status == "Released") {
        this.activeTabReleased = 2;
      } else { 
        this.activeTabInactive = 2;
      }
    },
  },
  mounted() {},
  watch: {},
  computed: {
    activeTabDraft: {
      get() {
        return this.$store.state.master.visitPlanGenerate.activeTabDraft;
      },
      set(val) {
        this.$store.commit("master/visitPlanGenerate/setActiveTabDraft", val);
      },
    },
    activeTabReleased: {
      get() {
        return this.$store.state.master.visitPlanGenerate.activeTabReleased;
      },
      set(val) {
        this.$store.commit(
          "master/visitPlanGenerate/setActiveTabReleased",
          val
        );
      },
    },
    activeTabInactive: {
      get() {
        return this.$store.state.master.visitPlanGenerate.activeTabInactive;
      },
      set(val) {
        this.$store.commit(
          "master/visitPlanGenerate/setActiveTabInactive",
          val
        );
      },
    },
  },
};
</script>
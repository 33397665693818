<template>
  <div class="tab-text">
    <data-table-log
      :baseUrl="this.baseUrl"
      :draw="draw"
      :customerVisitID="this.customerVisitID"
    />
  </div>
</template>

<script>
import DataTableLog from "./DataTableLog.vue";
export default {
  components: {
    DataTableLog,
  },
  props: {
    baseUrl: {
      type: String,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        draw: 0,
      };
    },
  },
  mounted() {},
  watch: {
    customerVisitID() {
      this.draw++;
    },
  },
  computed: {
    customerVisitID: {
      get() {
        return this.$store.state.master.visitPlanGenerate.customerVisitID;
      },
      set(val) {
        this.$store.commit("master/visitPlanGenerate/setCustomerVisitID", val);
      },
    },
  },
};
</script>
<template>
  <div class="tab-text">
    <data-table-customer
      :baseUrl="this.baseUrl"
      :draw="draw"
      :status="this.status"
      :customerVisitPlanLineID="this.customerVisitPlanLineID"
      @viewLog="handleViewLog"
    />
  </div>
</template>

<script>
import DataTableCustomer from "./DataTableCustomer.vue";
export default {
  components: {
    DataTableCustomer,
  },
  props: {
    baseUrl: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        draw: 0,
      };
    },
    handleViewLog({ id, status }) {
      this.$store.commit("master/visitPlanGenerate/setCustomerVisitID", id);
      if (status == "Draft") {
        this.activeTabDraft = 3;
      } else if(status=="Released") {
        this.activeTabReleased = 3;
      } else {
        this.activeTabInactive = 3;
      }
    },
  },
  mounted() {},
  watch: {
    customerVisitPlanLineID() {
      this.draw++;
    },
  },
  computed: {
    customerVisitPlanLineID: {
      get() {
        return this.$store.state.master.visitPlanGenerate
          .customerVisitPlanLineID;
      },
      set(val) {
        this.$store.commit(
          "master/visitPlanGenerate/setCustomerVisitPlanLineID",
          val
        );
      },
    },

    activeTabDraft: {
      get() {
        return this.$store.state.master.visitPlanGenerate.activeTabDraft;
      },
      set(val) {
        this.$store.commit("master/visitPlanGenerate/setActiveTabDraft", val);
      },
    },
    activeTabReleased: {
      get() {
        return this.$store.state.master.visitPlanGenerate.activeTabReleased;
      },
      set(val) {
        this.$store.commit(
          "master/visitPlanGenerate/setActiveTabReleased",
          val
        );
      },
    },
     activeTabInactive: {
      get() {
        return this.$store.state.master.visitPlanGenerate.activeTabInactive;
      },
      set(val) {
        this.$store.commit(
          "master/visitPlanGenerate/setActiveTabInactive",
          val
        );
      },
    },
  },
};
</script>